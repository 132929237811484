<template>
  <div class="home">
    <div class="content bg-indigo-600 pt-8 pb-16">
      <h1 class="text-3xl text-white font-bold text-center py-8 px-12">About Alley Web Design</h1>
      <p class="px-16 text-white text-lg">Web Design based in Great Yarmouth, Norfolk but serving customers from anywhere in the UK or Worldwide. Here at Alley Web Design we are passionate about building you great websites that suit your needs and budget. We are committed to providing high quality websites that deliver results. Offering packages from simple re-designs of existing websites to full site builds and hosting packages, we can take care of as much or as little as you need.</p>
    
      <div class="flex flex-col items-center justify-center">
        <h2 class="text-xl text-white font-semibold pt-6">Get in Touch</h2>
        <div class="flex py-4">
          <PhoneIcon class="h-8 w-8 text-white"/>
          <p class="text-white text-xl m-auto pl-1"><a href="tel:07791342003">07791342003</a></p>
        </div>
        <div class="flex">
          <AtSymbolIcon class="h-8 w-8 text-white"/>
          <p class="text-white text-xl m-auto pl-1"><a href="mailto: info@alleywebdesign.co.uk">info@alleywebdesign.co.uk</a></p>
        </div>
        
      </div>
      
    </div>
    <HomeServices />
  </div>
</template>

<script>
import HomeServices from '@/components/HomeServices.vue';
import { PhoneIcon, AtSymbolIcon } from '@heroicons/vue/solid'

// @ is an alias to /src


export default {
  name: 'HomeView',
  components: {
    HomeServices,
    PhoneIcon,
    AtSymbolIcon
}
}
</script>
