import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createHead } from '@vueuse/head'
import './index.css'

 
// Firebase imports
import { auth } from "./firebase/config";
import { onAuthStateChanged } from "firebase/auth";

const head = createHead()

let app
onAuthStateChanged(auth, () => {
    if(!app) {
        app = createApp(App).use(router).use(head).mount('#app')
        
    }
})






