<template>
  <Contact/>
</template>

<script>
import Contact from '@/components/Contact.vue'
export default {
  components: {
    Contact
  }
}
</script>

<style>

</style>