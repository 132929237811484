<template>
  <form @submit.prevent="handleSubmit" class="pt-2 md:pt-8 md:px-16">
      <div class="contact-info bg-indigo-600 text-center md:rounded-t-xl">
        <h2 class="text-white font-semibold text-3xl pt-2">Contact</h2>
    
        <div class="flex flex-col items-center justify-center pb-6">
        <div class="flex py-4">
          <PhoneIcon class="h-8 w-8 text-white"/>
          <p class="text-white text-xl m-auto pl-1"><a href="tel:07791342003">07791342003</a></p>
        </div>
        <div class="flex">
          <AtSymbolIcon class="h-8 w-8 text-white"/>
          <p class="text-white text-xl m-auto pl-1"><a href="mailto: info@alleywebdesign.co.uk">info@alleywebdesign.co.uk</a></p>
        </div>
        
      </div>
        </div>
        <div class="actual-form bg-indigo-50 flex flex-col justify-center px-4 py-4 md:px-16 md:rounded-b-xl ">
            <label for="name" class="py-2">Name:</label>
            <input type="text" name="name" v-model="name" required>

            <label for="phone" class="py-2">Phone Number:</label>
            <input type="text" name="phone" v-model="phone" required>

            <label for="email" class="py-2">Email:</label>
            <input type="email" v-model="email" required>

            <label for="service" class="py-2">Service:</label>
            <input type="text" name="service" v-model="service" placeholder="e.g 'Web Design'" required>

            <label for="query" class="py-2">More Info:</label>
            <textarea name="query" cols="30" rows="10" v-model="query" placeholder="Tell us a bit more about what you're looking for..."></textarea>
            <div class="py-2">
            <input type="checkbox" name="isChecked" required>
            <label for="isChecked" class="pl-2">By submitting this form you agree to our <router-link :to="{ name: 'TC'}" class="text-indigo-800 font-semibold">Terms, conditions + Privacy policy</router-link></label>
            </div>
            <div class="md:w-2/5" v-if="success"><p class="bg-green-300 p-2 my-2 rounded-lg font-semibold">Thanks for getting in touch. We aim to get back to you within 24 hours.</p></div>
            <button class="border-2 border-indigo-500 text-indigo-600 font-semibold text-xl px-4 py-2 w-24 rounded-lg hover:bg-indigo-500 hover:text-white text-center">Submit</button>
        </div>


  </form>
</template>

<script>
import { PhoneIcon, AtSymbolIcon } from '@heroicons/vue/solid'
import { ref } from '@vue/reactivity'

// Firestore imports
import { db } from '@/firebase/config'
import { addDoc, collection, FieldValue } from '@firebase/firestore'

export default {
components: {
    PhoneIcon,
    AtSymbolIcon
},
setup(props) {

    // Form entry refs
    const name = ref('')
    const phone = ref('')
    const email = ref('')
    const service = ref('')
    const query = ref('')
    let success = ref(false)


    const handleSubmit = async () => {
        console.log('form submitted: ', name.value, phone.value, email.value, service.value, query.value)

        let created = new Date()
        
        const colRef = collection(db, 'clients')

        await addDoc(colRef, {
          name: name.value,
          phone: phone.value,
          email: email.value,
          service: service.value,
          query: query.value,
          createdAt: created.toString()
        })

        // Make sure to reset form fields afterwards
          name.value = ''
          phone.value = ''
          email.value = ''
          service.value = ''
          query.value = ''
          success.value = true
    }

    return { handleSubmit, name, phone, email, service, query, success }
}
}
</script>

<style>

</style>