<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="py-12 bg-white">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="lg:text-center">
        <p class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-800 sm:text-4xl">What we offer</p>
        <p class="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">Below are just some of the things we offer. Don't see what you need <router-link :to="{ name: 'Contact'}" class="text-indigo-600">Get in touch.</router-link></p>
      </div>

      <div class="mt-10">
        <dl class="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
          <div v-for="feature in features" :key="feature.name" class="relative">
            <dt>
              <div class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                <component :is="feature.icon" class="h-6 w-6" aria-hidden="true" />
              </div>
              <p class="ml-16 text-lg leading-6 font-medium text-gray-900">{{ feature.name }}</p>
            </dt>
            <dd class="mt-2 ml-16 text-base text-gray-500">
              {{ feature.description }}
            </dd>
          </div>
        </dl>
      </div>
      <div>
          <p class="mt-8 max-w-2xl text-xl text-gray-500 lg:mx-auto">Check out our <router-link :to="{ name: 'Services'}" class="text-indigo-600">Services</router-link> page for a full breakdown of what we do or <router-link :to="{ name: 'Contact'}" class="text-indigo-600">Get in touch.</router-link></p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { GlobeAltIcon, LightningBoltIcon, DesktopComputerIcon, DeviceMobileIcon } from '@heroicons/vue/outline'

const features = [
  {
    name: 'Website build + design',
    description:
      'Website re-designs or full build packages. Whatever you need, we can do. All of our sites are fully responsive and use best accessibility practices.',
    icon: GlobeAltIcon,
  },
  {
    name: 'Hosting packages',
    description:
      'We can take care of hosting so you don\'t need to. Already have a hosting platform, no worries we can work with whatever provider you choose.',
    icon: DesktopComputerIcon,
  },
  {
    name: 'Lightning fast sites',
    description:
      'All of our sites are built using modern technology, meaning the sites are super quick and fully responsive.',
    icon: LightningBoltIcon,
  },
  {
    name: 'Social Media Packages',
    description:
      'Generating new customers through social media is so important these days. We offer packages to fully manage this for you.',
    icon: DeviceMobileIcon,
  },
]
</script>