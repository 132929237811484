<template>
<div class="grid lg:grid-cols-2">
  <div v-for="service in services" :key="service" class="pb-8 my-8 mx-2 md:mx-8">
      <div class="bg-indigo-500 rounded-t-lg">
      <h2 class="text-2xl text-center text-white font-bold pt-4">{{ service.title}}</h2>
      <p class="text-center text-xl font-bold pb-4 pt-2">Prices from <span class="text-xl text-white">£{{ service.price }}</span></p>
      </div>
      <div class="px-8">
          <p class="text-lg pt-4 pb-4">{{ service.description }}</p>
         <p class="text-lg pb-6">Includes as standard:</p>
        <ul class="grid md:grid-cols-2 pb-6">
          <li v-for="offer in service.offers" :key="offer" class="flex py-2"><StarIcon class="h-6 w-6 text-indigo-500"/>{{ offer }}</li>
      </ul>
      <div class="flex items-center justify-center justify-items-center my-3">
          <router-link :to="{ name: 'Contact'}" class="border-2 border-indigo-500 text-indigo-600 font-semibold text-xl px-4 py-2 rounded-lg hover:bg-indigo-500 hover:text-white">Find out more</router-link>
      </div>
      </div>
      
      
  </div>
  </div>
</template>

<script>
import { StarIcon } from '@heroicons/vue/solid'
export default {
    components: {StarIcon},
    setup() {
        
        const services = [
            {
                title: 'Website Design + Development',
                description: 'Building websites from scratch, or redesigning an existing site. We work with you to find out exactly what you need. Whether it\'s a quick one page app to showcase your work or a full site with content management system, we can help.',
                price: '299',
                icon: [],
                offers: ['Full design meeting', 'Options to suit your budget', 'Best Practices', 'Fully accessible', 'SEO optimized', '3 months initial ongoing support', 'Fully responsive for all device types',
                'Multiple site revisions after draft', 'Full site training']
            },
            {
                title: 'Hosting Package',
                description: 'Offering fully managed hosting solutions. Your site will have maximum up time and gets delivered to your customers fast.',
                price: '30 per month',
                offers: ['Up to 10GB storage and delivery per month', '1 Hour per month from us for monitoring/making small changes to site', 'Comprehensive Analytics', 'Priority support if there are any issues with the site']
            },
            {
                title: 'Social Media Package',
                description: 'Let\'s face it, nearly every business needs a social media presence these days. It\'s a brilliant way of reaching and engaging with new customers and keeps customers returning to your site. It can however be incredibly time consuming and difficult to keep content fresh. Our social media packages take all the hassle away. We can help with setting up new accounts and growing organic traffic. Unlike a lot of social media agencies we dont fill your profiles with thousands of bot accounts. We focus on generating genuine users who in turn become customers.',
                price: '99 per month',
                offers: ['Minimum two social media platforms', 'Minimum 3 unique posts per week', 'User engagement', 'Monthly Analytics', 'Like and interact with relevant people/businesses', 'Reseacrh into your area of business']
            },
            {
                title: 'The Not Sure package',
                description: 'The I\'m not really sure what I need or want package. Sometimes when deciding what you want for your website or social media can be difficult, especially when first starting out. How much you want to spend, what you really need etc. can be difficult. Get in touch with us and we will talk through what you are trying to acheive and offer no obligation options that suit you.',
                price: '0',
                offers: ['A free, no obligation consultation, online or over the phone', 'Options provided to suit your needs and budget' ]
            },
        ]

        return {services}
    }
}
</script>

<style>

</style>