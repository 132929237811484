<template>
 <Navbar/>
  <div class="wrapper text-gray-800">
    <router-view/>
  </div>
  <Footer/>
</template>

<script>
import Navbar from "./components/Navbar.vue";
import Footer from "./components/Footer.vue";
import { useHead } from '@vueuse/head'
import { computed, defineComponent, reactive } from "@vue/runtime-core";

export default defineComponent({
components: { Navbar, Footer },
setup() {
    const siteData = reactive({
      title: `Alley Web Design`,
      description: `Web design and development based in Great Yarmouth, Norfolk but serving customers all over the UK`,
    })

    useHead({
      // Can be static or computed
      title: computed(() => siteData.title),
      meta: [
        {
          name: `description`,
          content: computed(() => siteData.description),
          lang: 'en'
        },
      ],
    })
  },
})

</script>

<style>

</style>
