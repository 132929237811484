<template>
  <div class="bg-indigo-600 border border-2 flex flex-col items-center justify-center md:flex-row md:items-center md:justify-between mt-16 sm:mt-8 py-8">
    <router-link to="/" class="text-white font-bold text-2xl p-2 inline-flex items-center">
    <!-- <img src="../assets/img/logoNew.png" alt="" class="md:w-36 h-24 object-cover"> -->
    Alley Web Design
    </router-link>
    
    <div class="text-white font-bold">
      <router-link :to="{ name: 'home'}" class="mr-6">Home</router-link>
      <router-link :to="{ name: 'Services'}" class="mr-6">Services</router-link>
      <router-link :to="{ name: 'Contact'}" class="mr-6">Contact</router-link>
      <router-link :to="{ name: 'TC'}" class="mr-6">T+C's</router-link>
      <router-link :to="{ name: 'TC'}">Privacy</router-link>
    </div>
    <p class=" pt-4 mr-4 text-white"><span>&copy;</span><a href="#" class="">Alley Web Design</a> 2022</p>
  </div>
</template>

<script>
export default {

}

</script>

<style>

</style>