<template>
  <form @submit.prevent="handleSubmit" class="flex flex-col text-center py-16 px-12">
    <div>
      <h2 class="py-6 text-2xl text-white bg-indigo-500">Login</h2>
    </div>
    
  <div class="flex flex-col bg-indigo-50 px-6 py-4">
    <label for="email">Email:</label>
    <input type="email" name="email" v-model="email" required>

    <label for="password">Password:</label>
    <input type="password" name="password" v-model="password" required>
    <div class="text-center py-6">
    <button class="border-2 border-indigo-500 text-indigo-600 font-semibold text-xl px-4 py-2 rounded-lg hover:bg-indigo-500 hover:text-white text-center">Login</button>
    </div>
    <div v-if="error">{{ error }}</div>
  </div>
    
  </form>
</template>

<script>
import { ref } from '@vue/reactivity'
import { useRouter } from 'vue-router'
import useLogin from '@/composables/useLogin'
import { auth } from '@/firebase/config'
export default {
 setup(){
   const email = ref('')
   const password = ref('')
   

   const router = useRouter()

   const { login, error } = useLogin()

   const handleSubmit = async () => {
     await login(email.value, password.value)

     if(!error.value) {
       let user = auth.currentUser
      //  console.log('User logged in succesfully: ', user)
       router.push({name: 'Admin'})
     }
   }
   return { email, password, handleSubmit, error}
 }
}
</script>

<style>

</style>