<template>
  Admin page
  <button @click="handleClick">Log Out</button>
  <div>
    <ul>
      <li v-for="doc in documents" :key="doc.createdAt">
        <p>{{ doc.name }}</p>
        <p>{{ doc.email }}</p>
        <p>{{ doc.phone }}</p>
        <p>{{ doc.service }}</p>
        <p>{{ doc.query }}</p>
        <p>{{ doc.createdAt }}</p>
      </li>
    </ul>
  </div>
</template>

<script>
import getCollection from '@/composables/getCollection'
import { auth } from '@/firebase/config'
import { signOut } from '@firebase/auth'
import { useRouter } from 'vue-router'

// Firebase imports
import { db } from '@/firebase/config'
import { doc } from '@firebase/firestore'
import { addDoc, collection, FieldValue } from '@firebase/firestore'
import { watchEffect } from '@vue/runtime-core'
import getUser from '@/composables/getUsers'
export default {

setup() {
  const { documents } = getCollection('clients')
  const { user } = getUser()
  const  router  = useRouter()


  const handleClick = () => {
    signOut(auth)
  }

  
   watchEffect(() => {
  if(!user.value) {
    router.push('/login')
  }
  })

  return { documents, user, handleClick }
}
}
</script>

<style>

</style>