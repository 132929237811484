<template>
  <h1 class="text-gray-800 text-3xl text-center font-bold py-6">What We Do</h1>
  <SingleService/>
  
</template>

<script>
import SingleService from '@/components/SingleService.vue';

export default {
    components: { SingleService }

}
</script>

<style>

</style>